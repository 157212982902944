*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

footer {
  margin-top: 48px;
  background-color: #eee;
  border-top: 1px solid #e0e0e0;
}
